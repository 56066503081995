import React, { useState, useEffect } from 'react';
import './products.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, resetState, getallProducts } from '../../features/product/productSlice';
import { getCollections } from "../../features/collection/collectionSlice";
import loadingImg from '../loading.gif'

const Products = () => {
const [loading,setLoading]=useState(true)
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let page = parseInt(queryParams.get('page')) || 1;
  let state = queryParams.get('state') || '';

  const [cachedProducts, setCachedProducts] = useState({});
  const productState = useSelector((state) => state?.product?.prdt);
  const productStat = useSelector((state) => state?.product);
  const {isLoading,isSuccess}=productStat
useEffect(()=>{
if(isLoading){
  setLoading(true)
}
if(isSuccess){
  setLoading(false)
}
},[productStat])
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCollections());
  }, []);
  const collectionState = useSelector((state) => state.collection.collections);
useEffect(()=>{
setLoading(true)
},[page,state])
  useEffect(() => {
    if (!cachedProducts[`${page}-${state}`]) {
      dispatch(resetState());
      dispatch(getAllProducts({ page, state })).then((data) => {
        setCachedProducts({ ...cachedProducts, [`${page}-${state}`]: data.payload });
      });
    }
  }, [dispatch, page, state, cachedProducts]);
console.log(page)
  const updateURL = (updateParams) => {
    const searchParams = new URLSearchParams();

    if (updateParams.page !== undefined) {
      searchParams.set('page', updateParams.page);
    }

    if (updateParams.state !== undefined) {
      searchParams.set('state', updateParams.state);
    }

    navigate(`/products?${searchParams.toString()}`);
  };

  const nextPage = () => {

    page++;
    updateURL({ page, state });
  };

  const prevPage = () => {
    if (page > 1) {
      page--;
      updateURL({ page, state });
    }
  };

  const clearFilter = () => {
    setLoading(true)
    updateURL({ page: '', state: '' });
  };
const [cvalue,setCvalue]=useState("")

const productState1=useSelector((state)=>state?.product?.product)

const fetchcl=(e)=>{
  setCvalue(e.target.value)
  setTimeout(()=>{
      dispatch(getallProducts({limit:1000,sort:"order",page:1,collectionName:e.target.value})).then((data) => {
        setCachedProducts({ ...cachedProducts, [`${page}-${state}`]: data.payload });
      });
  },1000)
}

const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};

  return (
    <div className='products'>
      <div className="page-loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
      <div className='head'>
        <p className='heading'>Products</p>
        <Link to='/addProduct'>
          <button className='btn'>Add Product</button>
        </Link>
      </div>
      <div className='filter'>
        <button onClick={() => updateURL({ page, state: 'draft' })}>Draft</button>
        <button onClick={() => updateURL({ page, state: 'active' })}>Active</button>
        <button onClick={() => updateURL({ page, state: 'inactive' })}>Inactive</button>
        <button onClick={clearFilter}>Clear Filter</button>
        <select name="" id="" value={cvalue} onChange={(e)=>fetchcl(e)}>
          {
            collectionState?.map((item)=>{
              return <option value={item?.title}>{item?.title}</option>
            })
          }
        </select>
      </div>
      <div className='product-table'>
        {(cachedProducts[`${page}-${state}`] || productState1)?.products?.map((item, index) => {
          return (
            <Link to={`/addProduct/${item?._id}`} key={index}>
              <div className='product'>
                <div className='product-img'>
                  <img src={modifyCloudinaryUrl(item?.images && item?.images[0]?.url)} alt='' />
                </div>
                <p className='title'>{item?.title}</p>
                <p className='sku'>{item?.sku}</p>
                <p className='state' style={{ backgroundColor: item?.state === 'active' ? '#28ae2e' : '#ff3f3f' }}>
                  {item?.state}
                </p>
                <p
                  className='inventory'
                  style={{
                    color:
                      item.variants.reduce((total, item) => total + item?.quantity, 0) <= 5 ? 'rgb(189, 20, 20)' : 'green',
                  }}
                >
                  {item.variants.reduce((total, item) => total + item?.quantity, 0)} available
                </p>
                <p className='category'>{item?.collectionName}</p>
              </div>
            </Link>
          );
        })}
      </div>
      <div className='paginate'>
        <button onClick={prevPage} disabled={page === 1 ? true : false} style={{ backgroundColor: page === 1 ? 'grey' : '', cursor: page === 1 ? 'context-menu' : '' }}>
          Prev
        </button>
        <p>{page}</p>
        <button
          onClick={nextPage}
          disabled={productState?.length < 100 ? true : false}
          style={{ backgroundColor: productState?.length < 100 ? 'grey' : '', cursor: productState?.length < 100 ? 'context-menu' : '' }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Products;
