import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authServices";
import {toast} from 'react-toastify'
const getUserfromLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const initialState = {
  user: getUserfromLocalStorage,
  orders: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      return await authService.login(userData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAdmins=createAsyncThunk(
  "auth/getadmins",
  async(thunkAPI)=>{
    try {
      return await authService.getAdmins();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const getMonthlyData = createAsyncThunk(
  "orders/monthlydata",
  async (thunkAPI) => {
    try {
      return await authService.getMonthlyOrders();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getYearlyData = createAsyncThunk(
  "orders/yearlydata",
  async (thunkAPI) => {
    try {
      return await authService.getYearlyStats();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTodayData = createAsyncThunk(
  "orders/todaydata",
  async (thunkAPI) => {
    try {
      return await authService.getTodayOrders();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const gethistory = createAsyncThunk(
  "auth/gethistory",
  async (data,thunkAPI) => {
    try {
      return await authService.getAllHistory(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createhistory = createAsyncThunk(
  "auth/create-history",
  async (historyDetail,thunkAPI) => {
    try {
      return await authService.createHistory(historyDetail);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getYesterdayData = createAsyncThunk(
  "orders/yesterdaydata",
  async (thunkAPI) => {
    try {
      return await authService.getYesterdayOrders();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getWeekData = createAsyncThunk(
  "orders/weekdata",
  async (thunkAPI) => {
    try {
      return await authService.getWeekOrders();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCustomData = createAsyncThunk(
  "orders/customdata",
  async (data,thunkAPI) => {
    try {
      return await authService.getCustomOrders(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getLastData = createAsyncThunk(
  "orders/lastdata",
  async (_,thunkAPI) => {
    try {
      return await authService.getlastData();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createAnOrder=createAsyncThunk("auth/cart/create-order",async(orderDetails,thunkAPI)=>{
  try{
      return await authService.createOrder(orderDetails)
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})
export const getOrders = createAsyncThunk(
  "order/get-orders",
  async (data,thunkAPI) => {
    try {
      return await authService.getOrders(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
    
  }
);
export const getOrders1 = createAsyncThunk(
  "order/get-orders1",
  async (data,thunkAPI) => {
    try {
      return await authService.getOrders1(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
    
  }
);
export const getOldOrder = createAsyncThunk(
  "order/get-oldorders",
  async (thunkAPI) => {
    try {
      return await authService.getOldOrder();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
    
  }
);
export const getAllOrder = createAsyncThunk(
  "order/get-allorder",
  async (data,thunkAPI) => {
    try {
      return await authService.getAllOrder(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteabandoned = createAsyncThunk(
  "order/delete-abandoned",
  async (id,thunkAPI) => {
    try {
      return await authService.deleteAbandoned(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAbandoneds = createAsyncThunk(
  "order/get-abandoneds",
  async (data,thunkAPI) => {
    try {
      return await authService.getAbandoneds(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getUserOrder = createAsyncThunk(
  "order/get-order",
  async (id, thunkAPI) => {
    try {
      return await authService.getOrder(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getUserAbandoned = createAsyncThunk(
  "order/get-abandoned",
  async (id, thunkAPI) => {
    try {
      return await authService.getAbandoned(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAOrder = createAsyncThunk(
  "order/update-order",
  async (data, thunkAPI) => {
    try {
      return await authService.updateOrder(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const cancellingOrder = createAsyncThunk(
  "order/cancel-order",
  async (orderId, thunkAPI) => {
    try {
      return await authService.cancelOrder(orderId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const returningingOrder = createAsyncThunk(
  "order/return-order",
  async (orderId, thunkAPI) => {
    try {
      return await authService.returnOrder(orderId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const retrievingOrder = createAsyncThunk(
  "order/retrieve-order",
  async (orderId, thunkAPI) => {
    try {
      return await authService.retrieveOrder(orderId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const prepaidingOrder = createAsyncThunk(
  "prepaid-order",
  async (data, thunkAPI) => {
    try {
      return await authService.prepaidOrder(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const codingOrder = createAsyncThunk(
  "order/cod-order",
  async (orderId, thunkAPI) => {
    try {
      return await authService.codOrder(orderId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAAbandoned = createAsyncThunk(
  "order/update-abandoned",
  async (data, thunkAPI) => {
    try {
      return await authService.updateAbandoned(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const message = createAsyncThunk(
  "order/message",
  async ({ orderId, message, name, time }, thunkAPI) => {
      try {
          return await authService.message(orderId, message, name, time);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const history = createAsyncThunk(
  "order/history",
  async ({ orderId, message, name, time }, thunkAPI) => {
      try {
          return await authService.history(orderId, message, name, time);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const tracking = createAsyncThunk(
  "order/tracking",
  async (data, thunkAPI) => {
      try {
          return await authService.sendTracking(data);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const delivery = createAsyncThunk(
  "order/delivery",
  async (data, thunkAPI) => {
      try {
          return await authService.sendDelivery(data);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const updateAdmins = createAsyncThunk(
  "order/update-admins",
  async (data, thunkAPI) => {
      try {
          return await authService.updateAdmins(data);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // Add action to add a new order
    addOrder: (state, action) => {
      state.orders.push(action.payload);
    }
  },
  extraReducers: (buildeer) => {
    buildeer
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        if(state.isSuccess===true){
          toast.success("Login Successfully")
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(updateAdmins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdmins.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.adminupdate = action.payload;
        if(state.isSuccess===true){
          toast.success("Updated Successfully")
        }
      })
      .addCase(updateAdmins.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getAdmins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.team = action.payload;
        
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
       
      })
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
        state.message = "success";
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(gethistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(gethistory.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.getHistory = action.payload;
      })
      .addCase(gethistory.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(createhistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createhistory.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.createHistory = action.payload;
      })
      .addCase(createhistory.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getOldOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOldOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.getoldOrders = action.payload;
        state.message = "success";
      })
      .addCase(getOldOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getAllOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.getOrder = action.payload;
        state.message = "success";
      })
      .addCase(getAllOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getOrders1.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrders1.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.getOrder1 = action.payload;
        state.message = "success";
      })
      .addCase(getOrders1.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getAbandoneds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAbandoneds.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.abandoneds = action.payload;
        state.message = "success";
      })
      .addCase(getAbandoneds.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(createAnOrder.pending,(state)=>{
        state.isLoading=true;
    }).addCase(createAnOrder.fulfilled,(state,action)=>{
        state.isLoading=false;
        state.isError=false;
        state.isSuccess=true;
        state.orderedProduct=action.payload;
        if(state.isSuccess===true){
          toast.success("Order Created Successfully")
        }
    }).addCase(createAnOrder.rejected,(state,action)=>{
        state.isLoading=false;
        state.isError=true;
        state.isSuccess=false;
        state.message=action.error;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
    })
    .addCase(message.pending,(state)=>{
      state.isLoading=true;
  }).addCase(message.fulfilled,(state,action)=>{
      state.isLoading=false;
      state.isError=false;
      state.isSuccess=true;
      state.messageOrder=action.payload;
      if(state.isSuccess===true){
        toast.success("Message Posted")
      }
  }).addCase(message.rejected,(state,action)=>{
      state.isLoading=false;
      state.isError=true;
      state.isSuccess=false;
      state.message=action.error;
      if(state.isError===true){
        toast.error("Something Went Wrong")
      }
  })
  .addCase(history.pending,(state)=>{
    state.isLoading=true;
}).addCase(history.fulfilled,(state,action)=>{
    state.isLoading=false;
    state.isError=false;
    state.isSuccess=true;
    state.historyOrder=action.payload;

}).addCase(history.rejected,(state,action)=>{
    state.isLoading=false;
    state.isError=true;
    state.isSuccess=false;
    state.message=action.error;

})
      .addCase(getUserOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.singleorder = action.payload;
        state.message = "success";
      })
      .addCase(getUserOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getUserAbandoned.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserAbandoned.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.singleAbandoned = action.payload;
        state.message = "success";
      })
      .addCase(getUserAbandoned.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getMonthlyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonthlyData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.monthlyData = action.payload;
        state.message = "success";
      })
      .addCase(getMonthlyData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getCustomData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.customData = action.payload;
        state.message = "success";
      })
      .addCase(getCustomData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getYearlyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getYearlyData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.yearlyData = action.payload;
        state.message = "success";
      })
      .addCase(getYearlyData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getTodayData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTodayData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.todayData = action.payload;
        state.message = "success";
      })
      .addCase(getTodayData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getYesterdayData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getYesterdayData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.yesterdayData = action.payload;
        state.message = "success";
      })
      .addCase(getYesterdayData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getWeekData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWeekData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.weekData = action.payload;
        state.message = "success";
      })
      .addCase(getWeekData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(updateAOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.updateOrder = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Updated")
        }
      })
      .addCase(updateAOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(deleteabandoned.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteabandoned.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.updateOrder = action.payload;
        if(state.isSuccess===true){
          toast.success("Abandoned Deleted")
        }
      })
      .addCase(deleteabandoned.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;    
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(cancellingOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancellingOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.cancelledOrder = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Cancelled")
        }
      })
      .addCase(cancellingOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(returningingOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(returningingOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.returnededOrder = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Returned")
        }
      })
      .addCase(returningingOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(retrievingOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(retrievingOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.retrievedOrder = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Retrieved")
        }
      })
      .addCase(retrievingOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(prepaidingOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(prepaidingOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.prepaidorder = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Changed to Prepaid")
        }
      })
      .addCase(prepaidingOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(codingOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(codingOrder.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.codorder = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Changed to COD")
        }
      })
      .addCase(codingOrder.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(updateAAbandoned.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAAbandoned.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAbandoned = action.payload;
        if(state.isSuccess===true){
          toast.success("Order Updated")
        }
      })
      .addCase(updateAAbandoned.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(tracking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(tracking.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.sendTrack = action.payload;
        if(state.isSuccess===true){
          toast.success("Tracking Details Sended")
        }
      })
      .addCase(tracking.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(delivery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delivery.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.sendDel = action.payload;
        if(state.isSuccess===true){
          toast.success("Delivery Details Sended")
        }
      })
      .addCase(delivery.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getLastData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLastData.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.lastData = action.payload;
        state.message = "success";

        
      })
      .addCase(getLastData.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
       
      });
  },
});
export const { addOrder } = authSlice.actions;
export default authSlice.reducer;

