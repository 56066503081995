import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserOrder,updateAOrder,message ,history,codingOrder,cancellingOrder,retrievingOrder,prepaidingOrder, tracking, delivery, getAllOrder, createhistory, returningingOrder} from "../../features/auth/authSlice";
import generatePDF from 'react-to-pdf';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DvrIcon from '@mui/icons-material/Dvr';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ReceiptIcon from '@mui/icons-material/Receipt';
import loadingImg from '../loading.gif'
import './orders.css'
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ViewOrder = () => {
  const [loading,setLoading]=useState(true)

  const [open, setOpen] =useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const user=JSON.parse(localStorage.getItem("user"))

  const [state, setState] =useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <Link to={`/editOrder/${orderId}`}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <EditNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Order" />
            </ListItemButton>
          </ListItem></Link>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Duplicate" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={cancelClick}>
            <ListItemButton>
              <ListItemIcon>
                <CancelPresentationIcon />
              </ListItemIcon>
              <ListItemText primary={(orderState?.orderType===("COD")||orderState?.orderType===("Prepaid"))?"Cancel Order":"Retrieve Order"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={pdfDownload}>
            <ListItemButton>
              <ListItemIcon>
                <LocalPrintshopIcon />
              </ListItemIcon>
              <ListItemText primary="Print Packing Slip" />
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding onClick={markPaid}>
            <ListItemButton>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={orderState?.orderType==="COD"?"Mark as Prepaid":"Mark as COD"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={markConfirm}>
            <ListItemButton>
              <ListItemIcon>
                <EditNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Order Confirmed" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={markDelivered}>
            <ListItemButton>
              <ListItemIcon>
                <EditNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Mark as Delivered" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={markReturn}>
            <ListItemButton>
              <ListItemIcon>
                <EditNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Mark as Returned" />
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );
  const targetRef = useRef();
  const [num, setNum] = useState(0);

  const randomNumberInRange = (min, max) => {
      return Math.floor(Math.random()
          * (max - min + 1)) + min;
  };

  const [currentOrderState, setCurrentOrderState] = useState(null); // Add state to track the current order

  const pdfDownload = async () => {
    const splitOrders = splitOrder(orderState); // Split orders based on your logic
  
    // Loop through each split order
    for (let i = 0; i < splitOrders.length; i++) {
      const splitOrder = splitOrders[i];
  
      // Update the current order state to render correct packing slip
      setCurrentOrderState(splitOrder);
  
      // Wait for the state to update and the DOM to re-render
      await new Promise(resolve => setTimeout(resolve, 500));  // Small delay to ensure the DOM updates
  
      // Generate the PDF for the current order
      generatePDF(targetRef, { filename: `voguemine_order_${splitOrder.orderNumber}_${i + 1}.pdf` });
  
      // Dispatch history actions for each split order
      dispatch(history({
        orderId: splitOrder?._id,
        name: user?.firstname,
        time: new Date(),
        message: `Packing Slip Downloaded by ${user?.firstname}`
      }));
  
      dispatch(createhistory({
        name: user?.firstname,
        title: splitOrder?.orderNumber,
        sku: splitOrder?.orderNumber,
        productchange: "Print Packing Slip",
        time: new Date()
      }));
    }
  };
  const navigate=useNavigate()
  const [subTotal,setSubTotal]=useState(null)
  const location = useLocation();
  const orderId = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserOrder(orderId));
  }, [dispatch,orderId]);
  useEffect(()=>{
    
setLoading(true)
  },[])
  const orderState = useSelector((state) => state?.auth?.singleorder?.orders);

  const cancelClick = () => {
    if(orderState?.orderType===("COD")||orderState?.orderType===("Prepaid")){
      if (window.confirm("Do you want to cancel?")) {
        dispatch(cancellingOrder(orderState._id))
        dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Cancelled by ${user?.firstname}`}))
        dispatch(getUserOrder(orderId));

  } 
    }
    else{
      if (window.confirm("Do you want to Retrieve?")) {
        dispatch(retrievingOrder(orderState._id))
        dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Retrieved by ${user?.firstname}`}))
        dispatch(getUserOrder(orderId));

  } 
    }
    
    
  };
  const markReturn = () => {
    if (window.confirm("Do you want to mark this order as Returned?")) {
      dispatch(returningingOrder(orderState._id))
      dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Mark as Returned by ${user?.firstname}`}))
      dispatch(getUserOrder(orderId));


  }
       
  };
  const markPaid = () => {
    if(orderState?.orderType==="COD"){
      if (window.confirm("Do you want to make Prepaid?")) {
        dispatch(prepaidingOrder(orderState._id))
        dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Mark as Paid by ${user?.firstname}`}))
        dispatch(getUserOrder(orderId));

  } 
    }
    if(orderState?.orderType==="Prepaid"){
      if (window.confirm("Do you want to make COD?")) {
        dispatch(codingOrder(orderState._id))
        dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Mark as COD by ${user?.firstname}`}))
        dispatch(getUserOrder(orderId));

  } 
    }
    
    
    
  };


  const markDelivered=()=>{
    if (window.confirm("Do you want to mark this order as Delivered?")) {
      dispatch(delivery({name:orderState?.shippingInfo?.firstname,ordernumber:orderState?.orderNumber,number:orderState?.shippingInfo?.phone,orderId:orderState?._id}))
      dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Mark as Delivered by ${user?.firstname}`}))


  }
}

  const markConfirm=()=>{
    dispatch(updateAOrder({id:orderState._id,orderData:{
      shippingInfo:orderState.shippingInfo,
      paymentInfo:orderState.paymentInfo,
      orderItems:orderState.orderItems,
      totalPrice:orderState.totalPrice,
      shippingCost:orderState.shippingCost,
      orderType:orderState.orderType,
      discount:orderState.discount,
      finalAmount:orderState.finalAmount,
      orderStatus:orderState.orderStatus,
      createdAt:orderState.createdAt,
      orderNumber:orderState?.orderNumber,
      tag:orderState?.tag,
      orderCalled:"Called",   
    }}))
    dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Confirmed by ${user?.firstname}`}))
  }
const [tracknumber,setTracknumber]=useState("")
const [tracklink,setTracklink]=useState("https://www.dtdc.in/tracking.asp")
const [trackpartner,setTrackpartner]=useState("DTDC")

  useEffect (()=> {
    let sum=0;
    for(let index=0; index < orderState?.orderItems?.length; index++){
        sum =sum+(Number(orderState?.orderItems[index]?.quantity) *orderState?.orderItems[index]?.price)
        setSubTotal(sum)
    }
},[orderState])
 
  const timestamp = orderState?.createdAt; 

  const date = new Date(timestamp);

  const formattedDate = date.toLocaleString(undefined, {year:'numeric', month:'2-digit', day:'2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit', hour12:false});
  const orderState1=useSelector(state=>state?.auth?.getOrder)
    useEffect(()=>{
      dispatch(getAllOrder({limit:99999}))
    },[dispatch])
    const countEmailOccurrences = (arrayOfObjects, email) => {
      return arrayOfObjects?.reduce((count, obj) => {
        return obj?.shippingInfo?.email === email ? count + 1 : count;
      }, 0);
    };
  
    // Example usage:
    const arrayOfObjects = orderState1?.orders || []; // Assuming orderItems contains the objects with emails
    const emailToCount = orderState?.shippingInfo?.email || ''; // Assuming shippingInfo contains the email
    const emailCount = countEmailOccurrences(arrayOfObjects, emailToCount);
      // Log the email count to the console

      const [totalOrders,setTotalOrders]=useState(1)
  useEffect(() => {
    setTotalOrders(emailCount)
  }, [emailCount, emailToCount]);
const [msg,setMsg]=useState("")
  const messagePost=()=>{
    if(msg===""){
      toast.info("Please Enter the Message")
    }
    else{
      dispatch(message({orderId:orderState?._id,name:user?.firstname,message:msg,time:(new Date())}))
      setTimeout(()=>{
        setMsg("")
      },1000)
    }
  }
const handleClose1=()=>{
  if(tracknumber===""){
    toast.info("Please Enter Tracking Number")
  }
  
  dispatch(tracking({name:orderState?.shippingInfo?.firstname,ordernumber:orderState?.orderNumber,partner:`${trackpartner}`,link:` TrackingId: ${ tracknumber}, Tracking Link: ${ tracklink} `,number:orderState?.shippingInfo?.phone,orderId:orderState?._id}))
  setTimeout(()=>{
    dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Tracking Details TrackingId: ${ tracknumber}, Tracking Link: ${ tracklink} Send by ${user?.firstname}`}))
    setOpen(false);

  },1000)
}

const setTrackpartner1=(e)=>{
  setTrackpartner(e.target.value)

  if(e.target.value==="Online Express"){
    setTracklink("https://onlinexpress.co.in")

  }
  else if(e.target.value==="DTDC"){
    setTracklink("https://www.dtdc.in/tracking.asp")

  }
  else if(e.target.value==="Delivery"){
    setTracklink("https://www.delhivery.com/tracking")

  }
  else{
    setTracklink("https://www.shreemaruti.com/track-your-shipment")

  }


}
let trackingId = '';
    let trackingLink = '';

    // Ensure the trackingInfo and link exist and is a string
      if (orderState?.trackingInfo?.link && typeof orderState.trackingInfo.link === 'string') {
        // Split the string by commas to separate different parts
        const parts = orderState.trackingInfo.link.split(',');
        if (parts.length > 0) {
            // Attempt to match and extract the desired parts
            trackingId = parts.find(part => part.trim().startsWith('TrackingId'))?.trim();
            trackingLink = parts.find(part => part.trim().startsWith('Tracking Link'))?.trim();
        }
    }
   const [allOrders,setAllOrders]=useState() 
useEffect(()=>{
  const filterOrdersByEmail = () => {
    // Use the filter method to get all orders where the email matches the given email
    const filteredOrders = orderState1?.orders?.filter(order => order?.shippingInfo?.email === orderState?.shippingInfo?.email);
    return filteredOrders;
};
const ordersss=filterOrdersByEmail()
setAllOrders(ordersss)
},[orderState1,orderState?.shippingInfo?.email])


const [showOrderss,setShowOrderss]=useState("none")
const showOrders=()=>{
  if(showOrderss==="none"){
    setShowOrderss("block")
  }else{
    setShowOrderss("none")
  }
}

const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};


const splitOrder = (order) => {
  const maxSubtotal = 20000;
  let currentSubtotal = 0;
  let currentOrderItems = [];
  const result = [];

  // Calculate per-order discount
  const totalOrders = Math.ceil(order?.orderItems?.reduce((acc, item) => acc + (item?.price * item?.quantity), 0) / maxSubtotal);
  const perOrderDiscount = order?.discount / totalOrders;

  // Base order number (e.g., "VM1291")
  const baseOrderNumber = order?.orderNumber;

  order?.orderItems?.forEach((item, index) => {
    const itemTotalPrice = (item?.price * item?.quantity);

    if (currentSubtotal + itemTotalPrice > maxSubtotal) {
      // Generate order number with suffix (e.g., "VM1291A", "VM1291B")
      const suffix = String.fromCharCode(65 + result.length); // 'A' for first, 'B' for second, etc.

      result.push({
        ...order,
        orderNumber: `${baseOrderNumber}${suffix}`,  // Add suffix to order number
        orderItems: [...currentOrderItems],
        subtotal: currentSubtotal,
        // Subtract discount evenly and add shipping cost only to the first order
        finalAmount: currentSubtotal - perOrderDiscount + (result.length === 0 ? order?.shippingCost : 0),
      });
      currentOrderItems = [];
      currentSubtotal = 0;
    }

    currentOrderItems.push(item);
    currentSubtotal += itemTotalPrice;

    // Add last order
    if (index === order?.orderItems?.length - 1) {
      const suffix = String.fromCharCode(65 + result.length); // 'A' for first, 'B' for second, etc.

      result.push({
        ...order,
        orderNumber: `${baseOrderNumber}${suffix}`,  // Add suffix to order number
        orderItems: [...currentOrderItems],
        subtotal: currentSubtotal,
        finalAmount: currentSubtotal - perOrderDiscount + (result.length === 0 ? order?.shippingCost : 0),
      });
    }
  });

  return result;
};


console.log(orderState)
const [order,setOrder]=useState(null)
useEffect(()=>{
  setOrder(orderState)
},[orderState])
useEffect(()=>{
  if(order!==null){
    setLoading(false)
  }

},[orderState])

//   const targetRef = useRef(null);
  
//   return (
//     <div>
//       {orders.map((orderState, orderIndex) => (
//         <div key={orderIndex} style={{height:'auto', width:'794px', padding:'50px', position:'relative', marginBottom:'50px'}} ref={targetRef} className="packing-slip">
//           <div className='head'>
//             <h1>{orderState?.tag === "Voguemine" ? "VOGUEMINE" : orderState?.tag === "LevishLuxury" ? "LEVISH LUXURY" : "RAMPVALK"}</h1>
//             <div>
//               <p>Order #{orderState?.orderNumber}</p>
//               <p>{new Date(orderState?.createdAt).toLocaleDateString()}</p> {/* Assuming order date is in createdAt */}
//             </div>
//           </div>

//           <div className="detail">
//             <div>
//               <p>SHIP TO</p>
//               <p>{orderState?.shippingInfo?.firstname}</p>
//               <p>{orderState?.shippingInfo?.address}</p>
//               <p>{orderState?.shippingInfo?.city}</p>
//               <p>{orderState?.shippingInfo?.pincode}</p>
//               <p>{orderState?.shippingInfo?.state}</p>
//               <p style={{fontWeight: 500}}>NUMBER: {orderState?.shippingInfo?.phone}</p>
//             </div>
//             <div>
//               <p>BILL TO</p>
//               <p>{orderState?.shippingInfo?.firstname}</p>
//               <p>{orderState?.shippingInfo?.address}</p>
//               <p>{orderState?.shippingInfo?.city}</p>
//               <p>{orderState?.shippingInfo?.pincode}</p>
//               <p>{orderState?.shippingInfo?.state}</p>
//               <p style={{fontWeight: 500}}>NUMBER: {orderState?.shippingInfo?.phone}</p>
//             </div>
//           </div>

//           <hr />

//           <div className="main">
//             <div className="head">
//               <p style={{fontWeight: 500}}>ITEMS</p>
//               <p style={{fontWeight: 500}}>QUANTITY</p>
//             </div>

//             {orderState?.orderItems?.map((item, index) => (
//               <div className="item" key={index}>
//                 <div className="img">
//                   <img src={modifyCloudinaryUrl(item?.product?.images && item?.product?.images[0]?.url)} alt="img" />
//                   <div className="produ-detail">
//                     <p>{item?.product?.title}</p>
//                     <p>{item?.size} / {item?.color}</p>
//                     <p>{item?.product?.sku}</p>
//                     <p>RS. {item?.product?.price}</p>
//                   </div>
//                 </div>
//                 <p>{item?.quantity}</p>
//               </div>
//             ))}

//             <div className="total-amount">
//               <p style={{fontWeight: 500}}>Order Type: {orderState?.orderType}</p>
//               <p style={{fontWeight: 500}}>Shipping Cost: Rs. {orderState?.shippingCost}</p>
//               <p style={{fontWeight: 500}}>Discount: Rs. {orderState?.discount}</p>
//               <p style={{fontWeight: 500}}>Total Amount: Rs. {orderState?.finalAmount}</p>
//             </div>

//             <hr />

//             <div className="shop">
//               <p>Thank you for shopping with {orderState?.tag}!</p><br />
//               <p style={{fontWeight: 700}}>
//                 {orderState?.tag === "Voguemine" ? "VOGUEMINE" : orderState?.tag === "LevishLuxury" ? "LEVISH LUXURY" : "RAMPVALK"}
//               </p>
//               <p>H 119, Noida, UP, 201301, India</p>
//               <p>{orderState?.tag === "Voguemine" ? "info@voguemine.com" : orderState?.tag === "LevishLuxury" ? "levishluxury@gmail.com" : "info@rampvalk.com"}</p>
//               <p>+91 9899202079</p>
//               <p>{orderState?.tag === "Voguemine" ? "voguemine.com" : orderState?.tag === "LevishLuxury" ? "levishluxury.com" : "rampvalk.com"}</p>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };
const splitObjects = splitOrder(order);



  return (
    <div className="single-order">
      <div className="page-loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
      <p onClick={()=>navigate("/orders")} className="mb-4 title" style={{display:'flex',margin:'10px 0'}}><IoMdArrowRoundBack style={{cursor:'pointer'}}/>Orders</p>
      <div className="order-head">
          <div className="left">
            <p className="number">
                #{orderState?.orderNumber}
            </p>
            <p className={`type ${orderState?.orderType==="COD"?"cod":orderState?.orderType==="Cancelled"?"cancel":"paid"}`} >{orderState?.orderType}</p>
            <p className={`stati ${orderState?.orderStatus==="Ordered"?"status":orderState?.orderStatus==="Fullfilled"?"status1":"status2"}`}>{orderState?.orderStatus}</p>
            <p className="date"><DateRangeIcon className="date-icon"/>{new Date(orderState?.createdAt).toLocaleString('en-GB', { hour12: true })}</p>
          </div>
          <div className="right">
          <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <button style={{cursor:'pointer'}} onClick={toggleDrawer(anchor, true)}><MoreVertIcon className="icon"/></button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
            
            <button  onClick={handleClickOpen} style={{cursor:'pointer'}}>Fulfill</button>
            <BootstrapDialog
                     className='dialog'
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          FullFill Order
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className='dialog-content'>
         
          <div className="f-info">
            <input type="text" placeholder="Tracking Number" value={tracknumber} onChange={(e)=>setTracknumber(e.target.value)} style={{padding:'8px',width:'100%',marginBottom:'10px'}}/>
            <select name="" id="" value={trackpartner} onChange={(e)=>setTrackpartner1(e)} style={{padding:'8px',width:'100%',marginBottom:'10px'}}>
              <option value="DTDC">DTDC</option>
              <option value="Online Express">Online Express</option>
              <option value="Delivery">Delhivery</option>
              <option value="Shree Maruti">Shree Maruti</option>

            </select>
          </div>
          
          <div className="apply-changes">
            <button onClick={handleClose1} className='filter-btn'>Fullfill</button>
          </div>
        </DialogContent>
      </BootstrapDialog>
          </div>
        </div>
      <div className="order-detail">
        
       <div className="left-section">
       <div className="order-products">
        {
          orderState?.orderItems?.map((item,index)=>{
            return( 
<div className="prdt">
          <div className="left">
          <Link to={`/addProduct/${item?.product?._id}`}>
            <img src={modifyCloudinaryUrl(item?.product?.images && item?.product?.images[0]?.url)} alt="" />
            </Link>
            <div className="details">
              <p>{item?.product?.title}</p>
              <p><span>color:</span>{item?.color}</p>
              <p><span>size:</span>{item?.size}</p>
              <p><span>sku:</span>{item?.sku || item?.product?.sku}</p>
            </div>
          
    <p className="price">&#8377;{item?.price}</p>
    <p className="qty">{item?.quantity}</p>
    <p className="total">&#8377;{(item?.product?.price)*(item?.quantity)}</p>
    </div>
          </div>
            )
          })
        }
          

        </div>
        <div className="payment-info">
            <p className="head">Payment Summary</p>
          <div className="payment">
            <div className="left">
<p>Subtotal</p>
<p>Shipping</p>
<p>Discount</p>
<p>Total</p>
            </div>
            <div className="center">
              <p>{orderState?.orderItems?.length} Item</p>
              <p>{orderState?.orderType}</p>
              <p></p>
              <p></p>
            </div>
            <div className="right">
            <p>&#8377;{subTotal}</p>
              <p>&#8377;{orderState?.shippingCost}</p>
              <p>&#8377;{Math.floor(orderState?.discount)}</p>
              <p>&#8377;{subTotal+(orderState?.shippingCost)-(Math.floor(orderState?.discount))}</p>
            </div>
          </div>
        </div>
       </div>
       <div className="right-section">
        {
          orderState?.trackingInfo &&
          <div className="tracking">
        <p className="right-head">Tracking</p>
        <p className="id">{trackingId}</p>
        <p className="id">{trackingLink}</p>

        <p className="id">Partner: {orderState?.trackingInfo?.partner}</p>


        </div>
        }
        <div className="customer">
        <p className="right-head">Customer</p>
        <p className="customer-name"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXPgCd93aiNba8lHWJYHQ1C1YwBPwcH_NUmw&usqp=CAU" alt="" /><p>{orderState?.shippingInfo?.firstname} {orderState?.shippingInfo?.lastname}</p></p>
        <p className="customer-orders" style={{cursor:'pointer'}} onClick={showOrders}><DvrIcon className="order-icon"/><p className="order-count">{totalOrders} Orders</p></p>
        <div className="alls" style={{display:showOrderss}}>
          {
            allOrders && allOrders?.map((item,index)=>{
             return <p key={index} onClick={()=>navigate(`/orders/${item?._id}`)}>{item?.orderNumber} - &#8377;{item?.finalAmount}</p>
            })
          }
        </div>

        </div>
        <hr style={{opacity:'0.2'}}/>
        <div className="email">
          <p style={{fontWeight:600,fontSize:'13px',marginBottom:'15px'}}>Contact Info</p>
          <p><EmailIcon className="order-icon"/>{orderState?.shippingInfo?.email}</p>
          <p><LocalPhoneIcon className="order-icon"/>+91 {orderState?.shippingInfo?.phone}</p>

        </div>
        <hr style={{opacity:'0.2'}}/>

        <div className="email">
          <p style={{fontWeight:600,fontSize:'13px',marginBottom:'15px'}}>Shipping Address</p>
          <p>{orderState?.shippingInfo?.firstname}</p>
          <p>{orderState?.shippingInfo?.address}</p>
          <p>{orderState?.shippingInfo?.city}</p>
          <p>{orderState?.shippingInfo?.pincode}</p>
          <p>{orderState?.shippingInfo?.state}</p>
          <p>+91 {orderState?.shippingInfo?.phone}</p>
          <p>+91 {orderState?.shippingInfo?.mobile}</p>
          {/* <p>+916387017782</p> */}
        </div>
       </div>
      </div>
      <div>
        <div className="message">
          <div className="msg">
            <p>Comment</p>
            <div className="form">
              <p>Post Comment</p>
              <input type="text" placeholder="Enter Message" value={msg} onChange={(e)=>setMsg(e.target.value)}/>
              <button onClick={messagePost}>Comment</button>
            </div>
            <div className="comments">
              {
                orderState?.orderComment?.map((item)=>{
                  return <div className="comment-box">
                <div className="name">
                  <p>{item?.name}</p>
                  <p className="time">{new Date(item?.time).toLocaleString('en-GB', { hour12: true })}</p>
                </div>
                <p className="msg-c">{item?.message}</p>
              </div>
                })
              }
            </div>
          </div>
          <div className="history">
          <p style={{marginBottom:'10px'}}>Order History</p>
          {
                orderState?.orderHistory?.map((item)=>{
                  return <div className="history-box">
                  <div className="name">
                          <p>{item?.name}</p>
                          <p className="time">{new Date(item?.time).toLocaleString('en-GB', { hour12: true })}</p>
                        </div>
                        <p className="msg-c">{item?.message}</p>
                  </div>
                })
              }
          
          </div>
        </div>

        
      <div style={{height:'auto', width:'794px',padding:'50px', position:'absolute',left:'-200%'}} ref={targetRef} className="packing-slip">
      <div className='head'>
<h1>{currentOrderState?.tag==="Voguemine"? "VOGUEMINE":currentOrderState?.tag==="LevishLuxury"? "LEVISH LUXURY":"RAMPVALK"}</h1>
<div>
    <p>Order #{currentOrderState?.orderNumber}</p>
    <p>{formattedDate}</p>
</div>
      </div>
      <div className="detail">
        <div>
        <p>SHIP TO</p>
        <p>{currentOrderState?.shippingInfo?.firstname}</p>
          <p>{currentOrderState?.shippingInfo?.address}</p>
          <p>{currentOrderState?.shippingInfo?.city}</p>
          <p>{currentOrderState?.shippingInfo?.pincode}</p>
          <p>{currentOrderState?.shippingInfo?.state}</p>
          <p style={{fontWeight:500}}>NUMBER: {currentOrderState?.shippingInfo?.phone}</p>
        </div>
        <div>
        <p>BILL TO</p>
        <p>{currentOrderState?.shippingInfo?.firstname}</p>
          <p>{currentOrderState?.shippingInfo?.address}</p>
          <p>{currentOrderState?.shippingInfo?.city}</p>
          <p>{currentOrderState?.shippingInfo?.pincode}</p>
          <p>{currentOrderState?.shippingInfo?.state}</p>
          <p style={{fontWeight:500}}>NUMBER: {currentOrderState?.shippingInfo?.phone}</p>
        </div>
      </div>
      <hr />
      <div className="main">
        <div className="head">
            <p style={{fontWeight:500}}>ITEMS</p>
            <p style={{fontWeight:500}}>QUANTITY</p>
        </div>
        {
          currentOrderState?.orderItems?.map((item,index)=>{
            return(
            <div className="item" key={index}>
            <div className='img'>
                <img src={modifyCloudinaryUrl(item?.product?.images && item?.product?.images[0]?.url)} alt="img" />
                <div className="produ-detail">
                    <p>{item?.product?.title}</p>
                    <p>{item?.size} / {item?.color}</p>
                    <p>{item?.product?.sku}</p>
                    <p>RS. {item?.product?.price}</p>
                </div>
            </div>
            <p>{item?.quantity}</p>
        </div>

         ) })}
        

        <div className="total-amount">
        <p style={{fontWeight:500}}>Order Type: {currentOrderState?.orderType}</p>
            <p style={{fontWeight:500}}>Shipping Cost: {currentOrderState?.shippingCost}</p>
            <p style={{fontWeight:500}}>Total Amount: Rs.{currentOrderState?.finalAmount}</p>
        </div>
        <hr />
        <div className='shop'>
        <p>Thank you shopping with {currentOrderState?.tag}!</p><br/>
        <p  style={{fontWeight:700}}>{currentOrderState?.tag==="Voguemine"? "VOGUEMINE":currentOrderState?.tag==="LevishLuxury"? "LEVISH LUXURY":"RAMPVALK"}</p>
        <p>H 119, Noida, UP, 201301, India</p>
        <p>{currentOrderState?.tag==="Voguemine"? "info@voguemine.com":currentOrderState?.tag==="LevishLuxury"? "levishluxury@gmail.com":"info@rampvalk.com"}</p>
        <p>+91 9899202079</p>
        <p>{currentOrderState?.tag==="Voguemine"? "voguemine.com":currentOrderState?.tag==="LevishLuxury"? "levishluxury.com":"rampvalk.com"}</p>
        </div>
      </div>
    </div>
        {/* <Table columns={columns} dataSource={data1} /> */}
      </div>
      {/* <div>
      <h1>Split Orders</h1>
      {splitObjects?.map((splitOrder, index) => (
        <div key={index}>
          <h2>Order {index + 1}</h2>
          <p>Subtotal: {splitOrder.subtotal}</p>
          <p>Final Amount: {splitOrder.finalAmount}</p>
          <ul>
            {splitOrder.orderItems.map((item, idx) => (
              <li key={idx}>
                {item.product.title} - {item.color} - {item.size} - {item.quantity} x {item.price}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div> */}
      
    </div>
    
    
  );
};

export default ViewOrder;
