import React,{useEffect,useState} from 'react'
import './abandoned.css'
import {getAbandoneds,deleteabandoned} from '../../features/auth/authSlice'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import loadingImg from '../loading.gif'
import { MdDeleteForever } from "react-icons/md";
import { LuView } from "react-icons/lu";

const Abandoneds = () => {
  const navigate=useNavigate()
  const [loading,setLoading]=useState(true)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let page = parseInt(queryParams.get('page')) || 1;
    const dispatch=useDispatch()
    const orderState=useSelector(state=>state?.auth?.abandoneds)
    useEffect(()=>{
      dispatch(getAbandoneds({page}))
  setLoading(true)
    },[dispatch,page])

    useEffect(()=>{
      setLoading(true)
    },[])

    useEffect(()=>{
      if(orderState?.abondend){
        setLoading(false)
      }
      },[orderState])
    const updateURL = (updateParams) => {
      const searchParams = new URLSearchParams();
    
      if (updateParams.page !== undefined) {
        searchParams.set('page', updateParams.page);
      }
      navigate(`/abandoneds?${searchParams.toString()}`);
    };


    const nextPage=()=>{
      page++;
      updateURL({ page });
      }
      const prevPage=()=>{
        if (page > 1) {
          page--;
          updateURL({ page });
        }
      }
      const deleteAban=(id)=>{
        if (window.confirm("Do you want to Delete this Abandoned")) {
          dispatch(deleteabandoned(id))
          setTimeout(()=>{
            dispatch(getAbandoneds({page}))

          },500)

         }
      }
      const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
  return (
    <div className='abandoneds'>
     <div className="page-loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
<div className="head">
        <p className="heading">Abandoneds</p>
        <p></p>
      </div>
      <div className="product-table">
            {
              orderState?.abondend?.slice()?.reverse()?.map((item, index)=>{
                return <div className="product" >
                  <p className={`state ${item?.isPartial===true?"paid":"cod"}`}>{item?.isPartial===true?"Done":"NA"}</p>
                  <div className="delete">
                  <Link to={`/abandoneds/${item?._id}`} key={index}>
                  <p><LuView/></p>
                  </Link>

                    <p onClick={(e)=>deleteAban(item?._id)}><abbr title='Delete'><MdDeleteForever/></abbr></p>
                  </div>
                  <div className="product-img">
                    <img src={modifyCloudinaryUrl(item?.orderItems[0]?.product?.images[0]?.url)} alt=""/>
                    <div className="detail">
            <p className='number'><span>Order No.</span> #{item?.orderNumber}</p>
            <p style={{display:'flex',alignItems:'center'}}>{item?.tag} {
              item?.orderCalled==="Called"? <span style={{display:"flex",width:'10px',height:'10px',backgroundColor:'green',borderRadius:'50%',marginLeft:'7px'}}></span> : item?.orderCalled==="notpicked"? <span style={{display:"flex",width:'10px',height:'10px',backgroundColor:'red',borderRadius:'50%',marginLeft:'7px'}}></span>:""
            }</p>
                  <p className="time"><span>Date/Time:</span> {new Date(item?.createdAt).toLocaleString('en-GB', { hour12: true })}

</p>
                  <p className='amount'><span>Amount/Item:</span> &#8377;{item?.finalAmount} / {item?.orderItems?.length} Items</p>
                  
            </div>
                  </div>
                  <p className='name'><span>Name:</span>{item?.shippingInfo?.firstname} {item?.shippingInfo?.lastname}</p>
                  <p className='address'><span>Address:</span>{item?.shippingInfo?.city},{item?.shippingInfo?.state}</p>
                  <p className='phone'><span>Phone:</span>+91 {item?.shippingInfo?.phone}</p>
                 
        
                </div>
              })
            }



              </div>
      
      
     

      <div className="paginate">
        <button onClick={prevPage}>Prev</button>
        <p>{page}</p>
        <button onClick={nextPage}>Next</button>
      </div>
    </div>
  )
}

export default Abandoneds
