import './App.css';
import Header from './components/header/Header'
import Dashboard from './pages/Dashboard/Dashboard';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Products from './pages/products/Products';
import AddProduct from './pages/addProduct/AddProduct';
import Login from './pages/login/Login';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PublicRoute } from "./routing/PublicRoute";
import { PrivateRoute } from "./routing/PrivateRoute";
import Orders from './pages/orders/Orders';
import SingleOrder from './pages/orders/SingleOrder'
import CreateOrder from './pages/orders/CreateOrder';
import EditOrder from './pages/orders/EditOrder'
import CouponList from './pages/coupon/CouponList';
import Coupon from './pages/coupon/Coupon'
import UpdateCoupon from './pages/coupon/UpdateCoupon';
import CollectionList from './pages/collection/CollectionList';
import Collection from './pages/collection/Collection';
import CustomerList from './pages/customers/CustomerList';
import SingleCustomer from './pages/customers/SingleCustomer';
import SingleBlog from './pages/blogs/SingleBlog';
import Blog from './pages/blogs/Blog';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Abandoneds from './pages/abandoned/Abandoned';
import SingleAbandoned from './pages/abandoned/SingleAbandoned';
import OldOrder from './pages/oldOders/oldOrder';
import History from './pages/history/History';
import Banner from './pages/banners/Banner'
import BannerList from './pages/banners/bannerList'
import ReviewList from './pages/reviews/ReviewList';
import Team from './pages/team/Team';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}
function AppContent() {


  return (
    <DndProvider backend={HTML5Backend} basename="/">
      <>
        <Header/>
        <Routes>
          <Route path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path='/login' element={<PublicRoute><Login/></PublicRoute>}/>

          <Route path='/products' element={<PrivateRoute><Products/></PrivateRoute>}/>
          <Route path='/history' element={<PrivateRoute><History/></PrivateRoute>}/>

          <Route path='/addProduct' element={<PrivateRoute><AddProduct/></PrivateRoute>}/>
          <Route path='/addProduct/:id' element={<PrivateRoute><AddProduct/></PrivateRoute>}/>
          <Route path='/orders' element={<PrivateRoute><Orders/></PrivateRoute>}/>
          <Route path='/abandoneds' element={<PrivateRoute><Abandoneds/></PrivateRoute>}/>
          <Route path='/orders/:id' element={<PrivateRoute><SingleOrder/></PrivateRoute>}/>
          <Route path='/abandoneds/:id' element={<PrivateRoute><SingleAbandoned/></PrivateRoute>}/>
          <Route path='/orders/neworder' element={<PrivateRoute><CreateOrder/></PrivateRoute>}/>
          <Route path='/editOrder/:id' element={<PrivateRoute><EditOrder/></PrivateRoute>}/>
          <Route path="/coupon" element={<PrivateRoute><CouponList/></PrivateRoute>}/>
          <Route path="/coupon/new" element={<PrivateRoute><Coupon/></PrivateRoute>}/>
          <Route path="/coupon/:id" element={<PrivateRoute><UpdateCoupon/></PrivateRoute>}/>
          <Route path="/collection" element={<PrivateRoute><CollectionList/></PrivateRoute>}/>
          <Route path="/collections" element={<PrivateRoute><Collection/></PrivateRoute>}/>
          <Route path="/collections/:id" element={<PrivateRoute><Collection/></PrivateRoute>}/>
          <Route path="/banner" element={<PrivateRoute><BannerList/></PrivateRoute>}/>
          <Route path="/banner-new" element={<PrivateRoute><Banner/></PrivateRoute>}/>
          <Route path="/banner/:id" element={<PrivateRoute><Banner/></PrivateRoute>}/>
          <Route path="/customer" element={<PrivateRoute><CustomerList/></PrivateRoute>}/>
          <Route path="/customer/:id" element={<PrivateRoute><SingleCustomer/></PrivateRoute>}/>
          <Route path='/blogs' element={<PrivateRoute><Blog/></PrivateRoute>}/>
          <Route path='/singleblog' element={<PrivateRoute><SingleBlog/></PrivateRoute>}/>
          <Route path='/singleblog/:id' element={<PrivateRoute><SingleBlog/></PrivateRoute>}/>
          <Route path="/oldorders" element={<PrivateRoute><OldOrder/></PrivateRoute>}/>
          <Route path="/allratings" element={<PrivateRoute><ReviewList/></PrivateRoute>}/>
          <Route path="/addrating" element={<PrivateRoute><ReviewList/></PrivateRoute>}/>
          <Route path="/team" element={<PrivateRoute><Team/></PrivateRoute>}/>


        </Routes>
      </>
    </DndProvider>
  );
}


export default App;
