import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {useDispatch,useSelector} from 'react-redux'
import { getOldOrder } from '../../features/auth/authSlice';

const OldOrder = () => {
  const dispatch=useDispatch()
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const oldOrderState=useSelector((state)=>state?.auth?.getoldOrders)
  useEffect(()=>{
    dispatch(getOldOrder())
  })
  const data = []
  for (let i = 0; i < oldOrderState?.orders?.length; i++) {
    data.push({
      key:oldOrderState?.orders[i]?.orderNumber,
      email:oldOrderState?.orders[i]?.email,
      date:oldOrderState?.orders[i]?.dateTime,
      orderItem:oldOrderState?.orders[i]?.orderItems,
      amount:oldOrderState?.orders[i]?.finalAmount,
      orderType:oldOrderState?.orders[i]?.orderType
    })
    
  }
    

   
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
      style={{
        marginBottom: 8,
        display: 'block',
      }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Order',
      dataIndex: 'key',
      key: 'key',
      width: '50',
      ...getColumnSearchProps('key'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ...getColumnSearchProps('date'),
     
    },
    {
      title: 'orderItem',
      dataIndex: 'orderItem',
      key: 'orderItem',
      width: '20%',
      ...getColumnSearchProps('orderItem'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '50',
      ...getColumnSearchProps('amount'),
    },
    {
      title: 'Type',
      dataIndex: 'orderType',
      key: 'orderType',
      width: '20%',
      ...getColumnSearchProps('orderType'),
    },
  ];
  return <Table columns={columns} dataSource={data} scroll={{x:1000}}/>;
};
export default OldOrder;