import React, { useState,useEffect } from 'react'
import './blog.css'
import { useDispatch, useSelector } from "react-redux";
import {Link, useLocation,useNavigate} from 'react-router-dom'
import {getBlogs} from '../../features/blogs/blogSlice';
const Blog = () => {

  const dispatch=useDispatch()
  const navigate=useNavigate()
  const blogState=useSelector(state=>state?.blogs?.blogs)
  useEffect(()=>{
    dispatch(getBlogs())
  },[dispatch])
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  return (
    <div className='blogs'>
      <div className="blogs-head">
        <p>Blogs</p>
        <button onClick={()=>navigate("/singleblog")}>Create Blog</button>
      </div>
      <div className="blog-list">
        {
          blogState?.slice()?.reverse()?.map((item,index)=>{
            return <Link to={`/singleblog/${item?._id}`}>
            <div className="blog-card" key={index}>
            <img src={modifyCloudinaryUrl(item?.images[0]?.url)} alt={item?.title} />
            <div className="blog-detail">
                <p className="title">{item?.title}</p>
                <p className="desc" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                <p className="state" style={{color:'red',fontWeight:'500'}}>{item?.state}</p>
                <p className="time">{new Date(item?.createdAt).toLocaleDateString('en-GB')}</p>
            </div>
        </div>
            </Link>
          })
        }
        
      </div>
    </div>
  )
}

export default Blog
