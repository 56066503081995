import React, { useEffect } from 'react'
import './collection.css'
import {useDispatch,useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { getCollections } from '../../features/collection/collectionSlice'
const CollectionList = () => {
    const dispatch=useDispatch()
    const navigate=useNavigate()

    
    const collectionState = useSelector((state) => state?.collection?.collections);
    useEffect(()=>{
        dispatch(getCollections())
    },[dispatch])

    const modifyCloudinaryUrl = (url) => {
      const urlParts = url?.split('/upload/');
      return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };

  return (
    <div className='collection'>
      <div className="collection-head">
        <p>Collections</p>
        <button onClick={()=>navigate("/collections")}>Add Collection</button>
      </div>
      <div className="collection-list">
        {
            collectionState?.map((item,index)=>{
                return(
                    <Link to={`/collections/${item?._id}`}>
                        <div className="collection-card">
                            <div className="left">
                              {
                                item?.images?.map((items)=>{
                                  return <img src={modifyCloudinaryUrl(items?.url)} alt="" />
                                })
                              }
                                
                            </div>
                            <div className="right">
                            <p className='name'>{item?.category} / {item?.productCount}</p>
                            </div>
                        </div>
                    </Link>
                )
            })
        }
      </div>
    </div>
  )
}

export default CollectionList
