import React, { useState,useEffect } from 'react'
import './dashboard.css'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import {useDispatch,useSelector} from 'react-redux'
import {getMonthlyData, getOrders, getYearlyData,getTodayData,getYesterdayData,getWeekData,getCustomData, getLastData} from '../../features/auth/authSlice'
const Dashboard = () => {
  const [maxData,setMaxData]=useState("Month")
  const maxDataChange=(e)=>{
    setMaxData(e.target.value)
  }

  const [totalCount1, setTotalCount1] = useState(0);
  const [totalCount2, setTotalCount2] = useState(0);
  const [totalCount3, setTotalCount3] = useState(0);
  const [totalCount4, setTotalCount4] = useState(0);
  const [totalCount5, setTotalCount5] = useState(0);
  const [totalCount6, setTotalCount6] = useState(0);

const [startDate,setStartDate]=useState()
const [ednDate,setEndDate]=useState()
  const dispatch=useDispatch()
  const monthlyDataState=useSelector(state=>state?.auth?.monthlyData)
  const yearlyDataState=useSelector(state=>state?.auth?.yearlyData)
  const todayDataState=useSelector(state=>state?.auth?.todayData)
  const weekDataState=useSelector(state=>state?.auth?.weekData)
  const yesterdayDataState=useSelector(state=>state?.auth?.yesterdayData)
  const customDataState=useSelector(state=>state?.auth?.customData)
  const orderState=useSelector(state=>state?.auth?.orders?.orders)
  useEffect(()=>{
    dispatch(getMonthlyData())
    dispatch(getYearlyData())
    dispatch(getTodayData())
    dispatch(getWeekData())
    dispatch(getYesterdayData())
    dispatch(getLastData())
  },[dispatch])
  useEffect(()=>{
    dispatch(getOrders({page:1}))
  },[dispatch])
  useEffect(() => {
    // Function to count the total number of objects
    if(todayDataState && todayDataState.length>0){
      const countObjects = () => {
        let count = 0;
        todayDataState[0]?.items?.forEach((array) => {
          count += array.length; // Add the length of each array to the total count
        });
        setTotalCount5(count); // Update the state with the total count
      };
  
      countObjects();
    } // Call the function when the component mounts or arraysOfObjects changes
  }, [todayDataState]);
  useEffect(() => {
    // Function to count the total number of objects
    if(yearlyDataState && yearlyDataState.length>0){
      const countObjects = () => {
        let count = 0;
        yearlyDataState[0]?.items?.forEach((array) => {
          count += array.length; // Add the length of each array to the total count
        });
        setTotalCount4(count); // Update the state with the total count
      };
  
      countObjects();
    }
     // Call the function when the component mounts or arraysOfObjects changes
  }, [yearlyDataState]);
  useEffect(() => {
    // Function to count the total number of objects
    if(customDataState && customDataState.length>0){
      const countObjects = () => {
        let count = 0;
        customDataState[0]?.items?.forEach((array) => {
          count += array.length; // Add the length of each array to the total count
        });
        setTotalCount6(count); // Update the state with the total count
      };
  
      countObjects();
    }
     // Call the function when the component mounts or arraysOfObjects changes
  }, [customDataState]);
  useEffect(() => {
    // Function to count the total number of objects
    if(monthlyDataState && monthlyDataState.length>0){
      const countObjects = () => {
        let count = 0;
        monthlyDataState?.forEach((item) => {
          item?.items?.forEach((array)=>{
            count += array.length; // Add the length of each array to the total count

          })
        });
        setTotalCount3(count); // Update the state with the total count
      };
  
      countObjects();
    }
     // Call the function when the component mounts or arraysOfObjects changes
  }, [monthlyDataState]);
  useEffect(() => {
    // Function to count the total number of objects
    if(weekDataState && weekDataState.length>0){
      const countObjects = () => {
        let count = 0;
        weekDataState[0]?.items?.forEach((array) => {
          count += array.length; // Add the length of each array to the total count
        });
        setTotalCount2(count); // Update the state with the total count
      };
  
      countObjects();
    }
     // Call the function when the component mounts or arraysOfObjects changes
  }, [weekDataState]);
  useEffect(() => {
    // Function to count the total number of objects
    if(yesterdayDataState && yesterdayDataState.length>0){
      const countObjects = () => {
        let count = 0;
        yesterdayDataState[0]?.items?.forEach((array) => {
          count += array.length; // Add the length of each array to the total count
        });
        setTotalCount1(count); // Update the state with the total count
      };
  
      countObjects();
    }
     // Call the function when the component mounts or arraysOfObjects changes
  }, [yesterdayDataState]);
  const [tot,setTot]=useState()
  const [inc,setInc]=useState()
  const [ite,setIte]=useState()
useEffect(()=>{
setTot(yearlyDataState && yearlyDataState[0]?.count)
setInc(yearlyDataState && yearlyDataState[0]?.amount)
setIte(totalCount4 )
},[yearlyDataState,totalCount4])
const customDateGet=()=>{
   dispatch(getCustomData({startDate:startDate,endDate:ednDate}))
   
}
useEffect(()=>{
  setTot(customDataState && customDataState[0]?.totalCount)
  setInc(customDataState && customDataState[0]?.totalIncome)
  setIte(totalCount6)
},[customDataState,totalCount6])
const [m1,setM1]=useState(0)
const [m2,setM2]=useState(0)

useEffect(()=>{
let m3=0
let m4=0
monthlyDataState && monthlyDataState?.map((item)=>{
m3+=item?.count
m4+=item?.amount
})
setM1(m3)
setM2(m4)
},[monthlyDataState])
  return (
    <div className='dashboard'>
      <div className="top">
        <div className="total">
          <div className='data-div'>
          <div>
          <CalendarMonthIcon className='topIcon' />
          <p className='data'>Today Data</p>
          </div>
          </div>
          <div className="flexy">
            <div>
                <p>Orders</p>
                <p><span>{todayDataState && todayDataState[0]?.totalCount}</span></p>
            </div>
            <div>
                <p>Income</p>
                <p>&#8377;<span>{Math.floor(todayDataState && todayDataState[0]?.totalIncome)}</span></p>
            </div>
            <div>
                <p>Items</p>
                <p><span>{totalCount5}</span></p>
            </div>
          </div>
         </div>
         <div className="total">
          <div className='data-div'>
         <div> <LocalMallIcon className='topIcon' />
          <p className='data'>{maxData} Data</p></div>
            <select name="" id="" className='select' value={maxData} onChange={maxDataChange}>
              <option value="Yesterday">Yesterday</option>
              <option value="Week">This Week</option>
              <option value="Month">This Month</option>
              <option value="Year">This Year</option>
            </select>
            
          </div>
          <div className="flexy">
            <div>
                <p>Orders</p>
                <p><span>{
                  maxData==="Month"?monthlyDataState && m1
                  :
                  maxData==="Year"?yearlyDataState && yearlyDataState[0]?.count
                  :
                  maxData==="Week"?weekDataState && weekDataState[0]?.totalCount
                  :
                yesterdayDataState && yesterdayDataState[0]?.totalCount
                  
                  }</span></p>
            </div>
            <div>
                <p>Income</p>
                <p>&#8377;<span>{
                  maxData==="Month"?monthlyDataState && Math.floor(m2)
                  :
                  maxData==="Year"?yearlyDataState && Math.floor(yearlyDataState[0]?.amount)
                  :
                  maxData==="Week"?weekDataState && Math.floor(weekDataState[0]?.totalIncome)
                  :
                yesterdayDataState && Math.floor(yesterdayDataState[0]?.totalIncome)
                  
                  }</span></p>
            </div>
            <div>
                <p>Items</p>
                <p><span>{
                  
                    maxData==="Month"?totalCount3
                    :
                    maxData==="Year"?totalCount4
                    :
                    maxData==="Week"?totalCount2
                    :
                  totalCount1
                    
                    
                  }</span></p>
            </div>
          </div>
         </div>
         <div className="total">
          <div className='data-div'>
         <div> <LocalMallIcon className='topIcon' />
          </div>
            <div className="custom">
              <input type="date" name="" id="" onChange={(e)=>setStartDate(e.target.value)}/>
              <input type="date" name="" id="" onChange={(e)=>setEndDate(e.target.value)}/>

            </div>
            <button onClick={customDateGet}>Apply</button>
            
          </div>
          <div className="flexy">
            <div>
                <p>Orders</p>
                <p><span>{

tot
                  
                  }</span></p>
            </div>
            <div>
                <p>Income</p>
                <p>&#8377;<span>{

Math.floor(inc)

                  }</span></p>
            </div>
            <div>
                <p>Items</p>
                <p><span>{
                  
ite
                    
                    
                  }</span></p>
            </div>
          </div>
         </div>



      </div>
      <div className="bottom">
        <div className="orders">
          <p className='heading'>Recent Orders</p>
          <div className="table">
            <table>
            {
 orderState?.slice(Math.max(orderState?.length - 10, 0))?.reverse()?.map((item, index) => {
    return (
      <tr key={index}>
        <td><p>{item?.shippingInfo?.firstname}</p></td>
        <td><p>{item?.orderType}</p></td>
        <td>&#8377; {item?.finalAmount}</td>
        <td><p>{item?.orderItems?.length} items</p></td>
      </tr>
    );
  })
}
             
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
