import React,{useEffect,useState} from 'react'
import './orders.css'
import {getOrders, getOrders1, getUserOrder,updateAOrder,history,cancellingOrder} from '../../features/auth/authSlice'
/*import { getOldOrder } from '../../features/auth/authSlice'*/
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import SearchIcon from '@mui/icons-material/Search';
import loadingImg from '../loading.gif'
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";

const Orders = () => {
  
  const navigate=useNavigate()
const [loading,setLoading]=useState(true)
    const location = useLocation();
    const [numOrders, setNumOrders] = useState('');

    const queryParams = new URLSearchParams(location.search);
    let page = parseInt(queryParams.get('page')) || 1;
    const dispatch=useDispatch()
    const orderState=useSelector(state=>state?.auth?.orders.orders)
    const orderState1=useSelector(state=>state?.auth?.getOrder1)
useEffect(()=>{
setLoading(false)
},[orderState])
    useEffect(()=>{
      dispatch(getOrders({page}))
  setLoading(true)
    },[dispatch,page])

const [orders,setOrders]=useState([])
useEffect(()=>{
  setOrders(orderState)
},[orderState])

    const updateURL = (updateParams) => {
      const searchParams = new URLSearchParams();
    
      if (updateParams.page !== undefined) {
        searchParams.set('page', updateParams.page);
      }
      navigate(`/orders?${searchParams.toString()}`);
    };


    const nextPage=()=>{
      page++;
      updateURL({ page });
      }
      const prevPage=()=>{
        if (page > 1) {
          page--;
          updateURL({ page });
        }
      }
      const [selectedItem, setSelectedItem] = useState(null);

      const exportToExcel = () => {
        const ordersToExport = orderState.slice(-numOrders); // Get the last 'numOrders' orders
    
        // Map each order to include only the desired fields
        const formattedOrders = ordersToExport.map(order => ({
          'Order Date': new Date(order.createdAt).toLocaleString('en-GB', { hour12: true }),
          'Order Number': order.orderNumber,
          'First Name': `${order.shippingInfo.firstname} ${order.shippingInfo.lastname}`,
          'Address': `${order.shippingInfo.address}, ${order.shippingInfo.city},${order.shippingInfo.state},${order.shippingInfo.pincode}`,
          'Mobile': order.shippingInfo.phone,
          'Email': order.shippingInfo.email,
          'Final Amount': order.finalAmount,
          'Order Type': order.orderType,
          'State': order.state
        }));
    
        // Convert formatted orders data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    
        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, 'orders.xlsx');
      };
      const [searchValue, setSearchValue] = useState('');
     
      
      // Function to calculate relevance score

      const handleSelectionChange = (selected) => {
        if (selected[0]?.prod) {
          // If a product is selected, navigate to its details page
          navigate(`/orders/${selected[0]?.prod}`);
          dispatch(getUserOrder(selected[0]?.prod));
          setSelectedItem(null);
        }
      };
      const fetchSearch=()=>{
dispatch(getOrders1({page:page,search:searchValue}))
      }
      useEffect(()=>{
        setOrders(orderState1?.orders)
      },[orderState1])

      const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
  const user=JSON.parse(localStorage.getItem("user"))


      const confirmOrder=(orderState)=>{
        dispatch(updateAOrder({id:orderState._id,orderData:{
          shippingInfo:orderState.shippingInfo,
          paymentInfo:orderState.paymentInfo,
          orderItems:orderState.orderItems,
          totalPrice:orderState.totalPrice,
          shippingCost:orderState.shippingCost,
          orderType:orderState.orderType,
          discount:orderState.discount,
          finalAmount:orderState.finalAmount,
          orderStatus:orderState.orderStatus,
          createdAt:orderState.createdAt,
          orderNumber:orderState?.orderNumber,
          tag:orderState?.tag,
          orderCalled:"Called",   
        }}))
        dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Confirmed by ${user?.firstname}`}))
        dispatch(getOrders({page}))
      }
      const cancelOrder = (orderState) => {
        if(orderState?.orderType===("COD")||orderState?.orderType===("Prepaid")){
          if (window.confirm("Do you want to cancel?")) {
            dispatch(cancellingOrder(orderState._id))
            dispatch(history({orderId:orderState?._id,name:user?.firstname,time:(new Date()),message:`Order Cancelled by ${user?.firstname}`}))
            dispatch(getOrders({page}))    
      } 
        }  
      };
  return (
    <div className='orders'>
    <div className="page-loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
<div className="head">
        <p className="heading">Orders</p>
        <div className="searchs search1">
          <input type="search" name="" id="" placeholder='Search For Orders' onChange={(e)=>setSearchValue(e.target.value)}/>
<p onClick={fetchSearch}>       <SearchIcon/>
</p>        </div>
        <div className='down'>
        <Link to="/orders/neworder"><button className='btn'>Create Order</button></Link>
<div>
<input
            type="number"
            value={numOrders}
            onChange={e => setNumOrders(e.target.value)}
          />
          <button onClick={exportToExcel}>Export</button>
</div>
        </div>

      </div>
      <div className="searchs search2">
      <input type="search" name="" id="" placeholder='Search For Orders' onChange={(e)=>setSearchValue(e.target.value)}/>
      <p onClick={fetchSearch}><SearchIcon/></p>

  </div>
      <div className="product-table">
            {
              orders?.slice()?.reverse()?.map((item, index)=>{
                return <div className="product" >
                    <p className={`state ${item?.orderType==="COD"?"cod":item?.orderType==="Cancelled"?"cancel":"paid"}`}>{item?.orderType}{item?.isPartial===true?" / Paid":""}</p>
                    <Link to={`/orders/${item?._id}`} key={index}>
                  <div className="product-img">
                    <img src={modifyCloudinaryUrl(item?.orderItems[0]?.product?.images && item?.orderItems[0]?.product?.images[0]?.url)} alt=""/>
                    <div className="detail">
            <p className='number'><span>Order No.</span> #{item?.orderNumber} </p>
            <p style={{display:'flex',alignItems:'center'}}>{item?.tag} {
              item?.orderCalled==="Called"? <span style={{display:"flex",width:'10px',height:'10px',backgroundColor:'green',borderRadius:'50%',marginLeft:'7px'}}></span>:""
            }</p>
                  <p className="time"><span>Date/Time:</span> {new Date(item?.createdAt).toLocaleString('en-GB', { hour12: true })}

</p>
                  <p className='status'><span>Status:</span> {item?.orderStatus}</p>
                  <p className='amount'><span>Amount/Item:</span> &#8377;{item?.finalAmount} / {item?.orderItems?.length} Items</p>
                  
            </div>
                  </div>
                  </Link>
                  <p className='name'><span>Name:</span>{item?.shippingInfo?.firstname} {item?.shippingInfo?.lastname}</p>
                  <p className='address'><span>Address:</span>{item?.shippingInfo?.city},{item?.shippingInfo?.state}</p>
                  <p className='phone'><span>Phone:</span>+91 {item?.shippingInfo?.phone}</p>
                  <div className="marks">
                    <p className='ok oks' onClick={(e)=>confirmOrder(item)}><abbr title='Confirm Order'><IoCheckmarkDoneCircle className='ico1'/></abbr></p>
                    <p className='ok1 oks' onClick={(e)=>cancelOrder(item)}><abbr title="Cancel Order"><AiFillCloseCircle className='ico2'/></abbr></p>
                  </div>
                </div>
              })
            }



              </div>
      
      
     

      <div className="paginate">
        <button onClick={prevPage}>Prev</button>
        <p>{page}</p>
        <button onClick={nextPage}>Next</button>
      </div>
    </div>
  )
}

export default Orders
