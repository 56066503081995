import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import blogService from "./blogService";
import {toast} from 'react-toastify'
let loadingToast = null; // Reference to the loading toast

export const getBlogs = createAsyncThunk("blog/get-blogs", async (thunkAPI) => {
  try {
    return await blogService.getBlogs();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const createBlogs = createAsyncThunk(
  "blog/create-blogs",
  async (blogData, thunkAPI) => {
    try {
      return await blogService.createBlog(blogData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getABlog = createAsyncThunk(
  "blog/get-blog",
  async (id, thunkAPI) => {
    try {
      return await blogService.getBlog(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateABlog = createAsyncThunk(
  "blog/update-blog",
  async (brand, thunkAPI) => {
    try {
      return await blogService.updateBlog(brand);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteABlog = createAsyncThunk(
  "blog/delete-blog",
  async (id, thunkAPI) => {
    try {
      return await blogService.deleteBlog(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const resetState = createAction("Reset_all");

const initialState = {
  blogs: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.blogs = action.payload;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createBlogs.pending, (state) => {
        state.isLoading = true;
        loadingToast = toast.loading('Creating Blog...', { autoClose: false });

      })
      .addCase(createBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdBlog = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Blog Created")
        }
      })
      .addCase(createBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getABlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getABlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleBlog=action.payload;
      })
      .addCase(getABlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateABlog.pending, (state) => {
        state.isLoading = true;
        loadingToast = toast.loading('Updating Blog...', { autoClose: false });

      })
      .addCase(updateABlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedBlog = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Blog Updated")
        }
      })
      .addCase(updateABlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(deleteABlog.pending, (state) => {
        state.isLoading = true;
        loadingToast = toast.loading('Deleting Blog...', { autoClose: false });

      })
      .addCase(deleteABlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedBlog = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Blog Deleted")
        }
      })
      .addCase(deleteABlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(resetState, () => initialState);
  },
});
export default blogSlice.reducer;
