import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import bannerService from "./bannerService";
import {toast} from 'react-toastify'
let loadingToast = null; // Reference to the loading toast
export const getBanners = createAsyncThunk(
  "banner/get-banners",
  async (thunkAPI) => {
    try {
      return await bannerService.getBanners();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createBanner = createAsyncThunk(
  "banner/create-banner",
  async (bannerData, thunkAPI) => {
    try {
      return await bannerService.createBanner(bannerData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateABanner = createAsyncThunk(
  "banner/update-banner",
  async (banner, thunkAPI) => {
    try {
      return await bannerService.updateBanner(banner);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteABanner = createAsyncThunk(
  "banner/delete-banner",
  async (id, thunkAPI) => {
    try {
      return await bannerService.deleteBanner(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getABanner = createAsyncThunk(
  "banner/get-abanner",
  async (id, thunkAPI) => {
    try {
      return await bannerService.getBanner(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const resetState = createAction("Reset_all");

const initialState = {
  banner: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.banners = action.payload;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createBanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdBanner = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Banner Created")
        }
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(updateABanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateABanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedBanner = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Banner Updated")
        }
      })
      .addCase(updateABanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(deleteABanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteABanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedBanner = action.payload.title;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Banner Deleted")
        }
      })
      .addCase(deleteABanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getABanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getABanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleBanner = action.payload;
      })
      .addCase(getABanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});
export default bannerSlice.reducer;
