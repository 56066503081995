import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";


const getSearchProducts=async(data)=>{
  const response = await axios.get(`${base_url}product?search=${data.search}&limit=56`);

  return response.data;
}
const getProducts = async (data) => {
  
  const response = await axios.get(`${base_url}product?limit=${data.limit}&page=${data.page}`);

  return response.data;
};


const getallProducts=async(data)=>{
    
  let url = `${base_url}product?page=${data.page}&limit=${data.limit}&collectionName=${data.collectionName}&sort=${data.sort}`;

  // Check if collectionName is provided
  if (data && data.collectionName) {
      url += ``;
  }
  if (data && data.size) {
      url += `&size=${data.size}`;
  }

  // Add pagination parameters

  try {
      const response = await axios.get(url);
      
      if (response.data) {
          return response.data;
      }
  } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error appropriately
      throw error;
  }
}

const getAllProducts = async (data) => {
  const response = await axios.get(`${base_url}product?limit=100&page=${data.page}${data.sort && `&sort=${data.sort}`}${data.state && `&state=${data.state}`}`);

  return response.data;
};
const createProduct = async (product) => {
  const response = await axios.post(`${base_url}product/`, product, config);

  return response.data;
};


const updateProduct = async (product) => {
  const response = await axios.put(
    `${base_url}product/${product.id}`,
    {
      title: product.productData.title,
      alt: product.productData.alt,
      description: product.productData.description,
      category: product.productData.category,
      images: product.productData.images,
      price:product.productData.price,
      price1:product.productData.price1,
      handle:product.productData.handle,
      brand:product.productData.brand,
      sku:product.productData.sku,
      state:product.productData.state,
      collectionName:product.productData.collectionName,
      variants:product.productData.variants,
      tags:product.productData.tags,
      metaTitle:product.productData.metaTitle,
      metaDesc:product.productData.metaDesc,
      metaTitle1:product.productData.metaTitle1,
      metaDesc1:product.productData.metaDesc1,
      metaTitle2:product.productData.metaTitle2,
      metaDesc2:product.productData.metaDesc2,
      metaTitle3:product.productData.metaTitle3,
      metaDesc3:product.productData.metaDesc3,
      metaTitle4:product.productData.metaTitle4,
      metaDesc4:product.productData.metaDesc4,
    },
    config
  );

  return response.data;
};
const getProduct = async (id) => {
  const response = await axios.get(`${base_url}product/dashboard/${id}`, config);

  return response.data;
};

const deleteProduct = async (id) => {
  const response = await axios.delete(`${base_url}product/${id}`, config);

  return response.data;
};


const getAllRatings=async()=>{
  const response= await axios.get(`${base_url}product/getallratings`)
  if(response.data){
      return response.data
  }
}
const rateProduct = async (prodId, star, comment, name, email) => {
  try {
      const response = await axios.put(`${base_url}product/rating`, { prodId, star, comment, name, email });
      if (response.data) {
          return response.data;
      }
  } catch (error) {
      console.error("Error rating product:", error);
      // Handle error appropriately
      throw error;
  }
}

const productService = {
  getProducts,
  createProduct,
  deleteProduct,
  updateProduct,
  getProduct,
  getAllProducts,
  getallProducts,
  getSearchProducts,
getAllRatings,
rateProduct
};

export default productService;
