import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserAbandoned, updateAAbandoned,createhistory,createAnOrder, deleteabandoned } from "../../features/auth/authSlice";
import DateRangeIcon from '@mui/icons-material/DateRange';
import EmailIcon from '@mui/icons-material/Email';

import './abandoned.css'


const SingleAbandoned = () => {
  const user=JSON.parse(localStorage.getItem("user"))

const [firstname,setFirstname]=useState("")
const [lastname,setLastname]=useState("")
const [email,setEmail]=useState("")
const [phone,setPhone]=useState("")
const [mobile,setMobile]=useState("")
const [address,setAddress]=useState("")
const [city,setCity]=useState("")
const [state,setState]=useState("")
const [pincode,setPincode]=useState("")


  const navigate=useNavigate()
  const [subTotal,setSubTotal]=useState(null)
  const location = useLocation();
  const orderId = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAbandoned(orderId));
  }, [dispatch,orderId]);
  const orderState = useSelector((state) => state?.auth?.singleAbandoned);

  


  useEffect (()=> {
    let sum=0;
    for(let index=0; index < orderState?.abondend?.orderItems?.length; index++){
        sum =sum+(Number(orderState?.abondend?.orderItems[index]?.quantity) *orderState?.abondend?.orderItems[index]?.price)
        setSubTotal(sum)
    }
},[orderState])
 
  const timestamp = orderState?.abondend?.createdAt; 

  const date = new Date(timestamp);

  const formattedDate = date.toLocaleString(undefined, {year:'numeric', month:'2-digit', day:'2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit', hour12:false});
  const orderState1=useSelector(state=>state?.auth?.orders.orders)
    const countEmailOccurrences = (arrayOfObjects, email) => {
      return arrayOfObjects.reduce((count, obj) => {
        return obj?.shippingInfo?.email === email ? count + 1 : count;
      }, 0);
    };
  
    // Example usage:
    const arrayOfObjects = orderState1 || []; // Assuming orderItems contains the objects with emails
    const emailToCount = orderState?.abondend?.shippingInfo?.email || ''; // Assuming shippingInfo contains the email
    const emailCount = countEmailOccurrences(arrayOfObjects, emailToCount);
      // Log the email count to the console

      const [totalOrders,setTotalOrders]=useState(1)
  useEffect(() => {
    setTotalOrders(emailCount)
  }, [emailCount, emailToCount]);
useEffect(()=>{
  setFirstname(orderState?.abondend?.shippingInfo?.firstname)
  setLastname(orderState?.abondend?.shippingInfo?.lastname)
  setEmail(orderState?.abondend?.shippingInfo?.email)
  setPhone(orderState?.abondend?.shippingInfo?.phone)
  setMobile(orderState?.abondend?.shippingInfo?.mobile)
  setAddress(orderState?.abondend?.shippingInfo?.address)
  setCity(orderState?.abondend?.shippingInfo?.city)
  setState(orderState?.abondend?.shippingInfo?.state)
  setPincode(orderState?.abondend?.shippingInfo?.pincode)
},[orderState])

const updateMyOrder=()=>{
  dispatch(updateAAbandoned({id:orderId,orderData:{
    shippingInfo:{
      firstname:firstname,
      lastname:lastname,
      email:email,
      phone:phone,
      mobile:mobile,
      address:address,
      city:city,
      state:state,
      pincode:pincode,
    },
    orderItems:orderState.orderItems,
    totalPrice:orderState.totalPrice,
    shippingCost:orderState.shippingCost,
    orderType:orderState.orderType,
    discount:orderState.discount,
    finalAmount:orderState.finalAmount,
    createdAt:orderState.createdAt,
    orderNumber:orderState?.orderNumber,
    tag:orderState?.tag,
    orderCalled:orderState?.orderCalled,
    
  }}))
  dispatch(createhistory({name:user?.firstname,title:"Abandoned Updated",sku:"",productchange:`For #${orderState?.abondend?.orderNumber}`,time:new Date()}))
}




  const markConfirm=()=>{
    dispatch(updateAAbandoned({id:orderId,orderData:{
      shippingInfo:orderState.shippingInfo,
      orderItems:orderState.orderItems,
      totalPrice:orderState.totalPrice,
      shippingCost:orderState.shippingCost,
      orderType:orderState.orderType,
      discount:orderState.discount,
      finalAmount:orderState.finalAmount,
      createdAt:orderState.createdAt,
      orderNumber:orderState?.orderNumber,
      tag:orderState?.tag,
      orderCalled:"Called",
      
    }}))
    dispatch(createhistory({name:user?.firstname,title:"Abandoned Mark as Called",sku:"",productchange:`For #${orderState?.abondend?.orderNumber}`,time:new Date()}))
  }


  const notPicked=()=>{
    dispatch(updateAAbandoned({id:orderId,orderData:{
      shippingInfo:orderState.shippingInfo,
      orderItems:orderState.orderItems,
      totalPrice:orderState.totalPrice,
      shippingCost:orderState.shippingCost,
      orderType:orderState.orderType,
      discount:orderState.discount,
      finalAmount:orderState.finalAmount,
      createdAt:orderState.createdAt,
      orderNumber:orderState?.orderNumber,
      tag:orderState?.tag,
      orderCalled:"notpicked",
      
    }}))
    dispatch(createhistory({name:user?.firstname,title:"Abandoned Mark as Not Picked",sku:"",productchange:`For #${orderState?.abondend?.orderNumber}`,time:new Date()}))
  }


const deleteAbandoned=()=>{
  if (window.confirm("Do you want to Delete this Abandoned")) {
   dispatch(deleteabandoned(orderId))
   dispatch(createhistory({name:user?.firstname,title:"Abandoned Deleted",sku:"",productchange:`For #${orderState?.abondend?.orderNumber}`,time:new Date()}))
   navigate("/abandoneds")
  }
}

  const createOrderAban=()=>{
    if (window.confirm("Do you want to Create this Order")) {
    dispatch(createAnOrder({
      shippingInfo:orderState?.abondend?.shippingInfo,
      paymentInfo:
        {
          razorpayOrderId:"COD",
          razorpayPaymentId:"COD"
        },
        orderItems:orderState?.abondend?.orderItems,
        totalPrice:orderState?.abondend?.totalPrice,
        shippingCost:orderState?.abondend?.shippingCost,
        orderType:"COD",
        discount:orderState?.abondend?.discount,
        finalAmount:orderState?.abondend?.finalAmount,
        tag:orderState?.abondend?.tag
      
    }))
    dispatch(createhistory({name:user?.firstname,title:"Order Created from Abandoned",sku:"",productchange:`For ${orderState?.abondend?.shippingInfo?.firstname}, Amount:${orderState?.abondend?.finalAmount}, orderType:COD Number:${orderState?.abondend?.orderNumber}, Items:${orderState?.abondend?.orderItems?.length}`,time:new Date()}))
  

  }}
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  return (
    <div className="single-order single-aban">
      <p onClick={()=>navigate("/abandoneds")} className="mb-4 title" style={{display:'flex',margin:'10px 0'}}><IoMdArrowRoundBack style={{cursor:'pointer'}}/>Abandoneds</p>
      <div className="order-head">
          <div className="left">
            <p className="number">
                #{orderState?.abondend?.orderNumber}
            </p>
            <p className={`type ${orderState?.abondend?.orderType==="COD"?"cod":orderState?.abondend?.orderType==="Cancelled"?"cancel":"paid"}`} >{orderState?.abondend?.orderType}</p>
            <p className="date"><DateRangeIcon className="date-icon"/>{new Date(orderState?.abondend?.createdAt).toLocaleString('en-GB', { hour12: true })}</p>
          </div>
          <div className="right">
         
          <button className="btn" onClick={createOrderAban}>Create Order</button>
            <button className="btn"  onClick={markConfirm}>Called</button>
            <button className="btn"  onClick={notPicked}>N.P</button>
            <button className="btn"  onClick={updateMyOrder}>Update</button>
            <button className="btn"  onClick={deleteAbandoned}>Delete</button>

          </div>
        </div>
      <div className="order-detail">
        
       <div className="left-section">
       <div className="order-products">
        {
          orderState?.abondend?.orderItems?.map((item,index)=>{
            return( <Link to={`/addProduct/${item?.product?._id}`}>
<div className="prdt">
          <div className="left">
            <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" />
            <div className="details">
              <p>{item?.product?.title}</p>
              <p><span>color:</span>{item?.color}</p>
              <p><span>size:</span>{item?.size}</p>
              <p><span>sku:</span>{item?.product?.sku}</p>
            </div>
          
    <p className="price">&#8377;{item?.price}</p>
    <p className="qty">{item?.quantity}</p>
    <p className="total">&#8377;{(item?.product?.price)*(item?.quantity)}</p>
    </div>
          </div>
          </Link>
            )
          })
        }
          

        </div>
        <div className="payment-info">
            <p className="head">Payment Summary</p>
          <div className="payment">
            <div className="left">
<p>Subtotal</p>
<p>Shipping</p>
<p>Discount</p>
<p>Total</p>
            </div>
            <div className="center">
              <p>{orderState?.abondend?.orderItems?.length} Item</p>
              <p>{orderState?.abondend?.orderType}</p>
              <p></p>
              <p></p>
            </div>
            <div className="right">
            <p>&#8377;{subTotal}</p>
              <p>&#8377;{orderState?.abondend?.shippingCost}</p>
              <p>&#8377;{Math.floor(orderState?.abondend?.discount)}</p>
              <p>&#8377;{subTotal+(orderState?.abondend?.shippingCost)-(Math.floor(orderState?.abondend?.discount))}</p>
            </div>
          </div>
        </div>
       </div>
       <div className="right-section">
        <div className="customer">
        <p className="right-head">Customer</p>
        <p className="customer-name"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXPgCd93aiNba8lHWJYHQ1C1YwBPwcH_NUmw&usqp=CAU" alt="" /><p>{orderState?.abondend?.shippingInfo?.firstname} {orderState?.abondend?.shippingInfo?.lastname}</p></p>

        </div>
        <hr style={{opacity:'0.2'}}/>
        <div className="email">
          <p style={{fontWeight:600,fontSize:'13px',marginBottom:'15px'}}>Contact Info</p>
          <p><EmailIcon className="order-icon"/>{orderState?.abondend?.shippingInfo?.email}</p>
          <p>+91 {orderState?.abondend?.shippingInfo?.phone}</p>

        </div>
        <hr style={{opacity:'0.2'}}/>

        <div className="email">
          <p style={{fontWeight:600,fontSize:'13px',marginBottom:'15px'}}>Shipping Address</p>
          <p><span>Firstname:</span><input type="text" placeholder="Firstname" value={firstname} onChange={(e)=>setFirstname(e.target.value)}/></p>
          <p><span>Lastname:</span><input type="text" placeholder="Lastname" value={lastname} onChange={(e)=>setLastname(e.target.value)}/></p>
          <p><span>Email:</span><input type="email" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)}/></p>
          <p><span>Address:</span><input type="text" placeholder="Address" value={address} onChange={(e)=>setAddress(e.target.value)}/></p>
          <p><span>City:</span><input type="text" placeholder="City" value={city} onChange={(e)=>setCity(e.target.value)}/></p>
          <p><span>Pincode:</span><input type="number" placeholder="Pincode" value={pincode} onChange={(e)=>setPincode(e.target.value)}/></p>
          <p><span>State:</span><input type="text" placeholder="State" value={state} onChange={(e)=>setState(e.target.value)}/></p>
          <p><span>Phone:</span><input type="number" placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/></p>
          <p><span>Alternate:</span><input type="number" placeholder="Alternate" value={mobile} onChange={(e)=>setMobile(e.target.value)}/></p>
        </div>
       </div>
      </div>
      <div>
      </div>
      
    </div>
    
    
  );
};

export default SingleAbandoned;
